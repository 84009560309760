import * as React from "react";
import Layout from "../components/layout";
import References from "../components/references";

const StudiosPage = () => {
  return (
    <Layout>
      <h2 className="text-3xl font-bold text-left mb-4">Studios</h2>
    </Layout>
  );
};

export default StudiosPage;

export const Head = () => <title>Studios</title>;
